<template>
  <div class="w-full mt-5 mb-5">
    <div
      class="rounded border-dashed w-full border-2 p-4"
      :class="{ 'border-red-500': $v.$error, 'border-blue-500': !$v.$error }"
      @click="
        () => {
          if ($refs.fileInput && !file_obj) {
            $refs.fileInput.click();
          }
        }
      "
    >
      <input
        id="inputFile"
        style="opacity: 0; height: 0px; width: 0px; margins: 0px; position: absolute;"
        type="file"
        name="file"
        ref="fileInput"
        accept="*"
        @change="onFileSelected"
      />
      <div class="file-type-icons" v-if="!loading && !file_name && !file_URL">
        <div>
          <p class="mb-1 font-bold text-center">اضغط لاختيار الملف الذي تريد إرساله</p>
          <p class="mb-8 text-center">يجب ألا يتجاوز حجم الملف 10 ميغا بايت</p>
        </div>
        <div class="flex justify-center align-center items-center">
          <!-- #228cff -->
          <font-awesome-icon icon="file" size="2x" color="#b5b5b5" />
          <font-awesome-icon icon="file-pdf" size="2x" color="#b5b5b5" />
          <font-awesome-icon icon="file-image" size="2x" color="#b5b5b5" />
        </div>
      </div>
      <div v-else-if="loading">
        <p class="mb-3 text-center text-blue-500 font-bold text-lg rtl">يتم رفع الصورة ...</p>
        <div class="flex justify-center mt-8 mb-3">
          <loaderComponent color="#63b3ed" class="text-cetner"></loaderComponent>
        </div>
      </div>
      <div v-else-if="file_obj || (file_name && file_URL)">
        <div v-if="file_type !== 'image'" class="mb-4 ">
          <p class="text-center text-lg mb-3">اسم الملف المختار:</p>
          <p class="font-bold text-center text-lg">{{ file_name }}</p>
        </div>
        <div v-show="file_type === 'image'" class="flex justify-center align-center items-center">
          <img
            ref="IMAGE_PREVIEW_CONTAINER"
            :src="file_URL"
            alt=""
            id="IMAGE_PREVIEW_CONTAINER"
          />
        </div>
        <div class="flex justify-center align-center items-center">
          <button @click.stop="resetFileObj" class="mujeeb-button">تغيير الملف</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileUploadMixin from "@/mixins/fileUploadMixin";
import templatesTypes from "./config/templatesTypes";
import loaderComponent from "@/components/loading/SpringSpinner"
import { EventBus } from "@/utils/EventBus";
export default {
  mixins: [fileUploadMixin],
  components: {loaderComponent},
  props: {
    replyData_prop: {
      type: Object
    }
  },
  data() {
    return {
      ...this.__parseDataProperties("imageFile"),
      name: "imageFile"
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      this.$emit("input", { data: { file_URL: this.file_URL, file_name: this.file_name, file_type: this.file_type }, valid: !this.$v.$invalid });
    },
    __parseDataProperties(name) {
      return Object.keys(templatesTypes.inputs[name]).reduce((acc, inputName) => {
        let input = templatesTypes.inputs[name][inputName];
        acc[inputName] = JSON.parse(JSON.stringify(input.default));
        return acc;
      }, {});
    }
  },
  created() {
    EventBus.$on("vuelidate_touch", this.$v.$touch);
    if (this.replyData_prop) {
      let temp = JSON.parse(JSON.stringify(this.replyData_prop));
      this.file_URL = temp.file_URL;
      this.file_name = temp.file_name;
      this.file_type = temp.file_type;
    }
  },
  watch: {
    'file_URL': function(newVal) {
      this.submit();
    }
  },
  validations() {
    return templatesTypes.validations.imageFile;
  }
};
</script>

<style lang="scss" scoped>
.file-type-icons {
  svg {
    margin: 0px 10px;
  }
}
#IMAGE_PREVIEW_CONTAINER {
  height: 300px;
  max-width: 400px;
  object-fit: contain;
}
</style>
