import { EventBus } from "../utils/EventBus";
export default {
  data() {
    return {
      stagedButton: null,
      showRolesModal: false,
      selectedRole: null,
      showButtonRoles: false,
      selectedButtonIndex: null,
      scrollLeft: 0,
      clickedButton: null,
      clickedSettingsButtonIndex: null
    };
  },
  methods: {
    onAddNewButton() {
      if (this.buttons.length < 3) {
        this.$v.buttons.$model.push({ name: null, role: null, id: this.$randomId(6) });
      } else {
        console.log("you can not add new button");
      }
    },
    onShowRolesClicked(button, index, $event) {
      EventBus.$emit("show_button_rols", this.id);
      this.stagedButton = button;
      const stagedButtonRole = this.$getAtPath(["role", "action"], button);
      if (stagedButtonRole) {
        this.selectedRole = stagedButtonRole;
        if (stagedButtonRole !== "OPEN_CONTACTUS") {
          this.showRolesModal = true;
        }
      } else {
        if (this.selectedButtonIndex != null && this.selectedButtonIndex === index) {
          this.showButtonRoles = !this.showButtonRoles;
        } else {
          this.selectedButtonIndex = index;
          this.showButtonRoles = true;
        }
      }

      let clickedButton = $event.target;
      if (clickedButton.tagName === "svg") {
        clickedButton = clickedButton.parentNode;
      } else if (clickedButton.tagName === "path") {
        clickedButton = clickedButton.parentNode.parentNode;
      }
      this.clickedButton = clickedButton;
      this.clickedSettingsButtonIndex = index;

      const buttonRolesElement = document.getElementById(this.id + "" + index);
      if (buttonRolesElement) {
        buttonRolesElement.style.top = `${this.clickedButton.offsetTop + 30}px`;
        buttonRolesElement.style.left = `${this.clickedButton.offsetLeft -
          172 -
          this.scrollLeft}px`;
      }
    },
    onRoleClicked(button, value, index) {
      this.selectedRole = value;
      switch (value) {
        case "OPEN_CONTACTUS":
          this.$v.buttons.$model[index].role = { action: value, data: "" };
          this.showButtonRoles = false;
          break;
        default:
          this.showRolesModal = true;
          break;
      }
    },
    onRoleDataSubmited(roleData) {
      this.showRolesModal = false;
      this.stagedButton.role = {
        action: this.selectedRole,
        data: roleData
      };
      this.selectedButtonIndex = null;
      this.stagedButton = null;
      this.selectedRole = null;
      this.$v.$touch();
    },
    onDeleteButton(index) {
      this.$delete(this.buttons, index);
      this.showButtonRoles = false;
    },
    closeButtonRoleMenu(id) {
      if (id !== this.id) {
        this.showButtonRoles = false;
      }
    }
  },
  mounted() {
    let that = this;
    EventBus.$on("show_button_rols", this.closeButtonRoleMenu);
    EventBus.$on("carousel_scroll", function(payLoad) {
      const buttonRolesElement = document.getElementById(
        that.id + "" + that.clickedSettingsButtonIndex
      );
      that.scrollLeft = payLoad;
      if (buttonRolesElement && that.clickedButton) {
        buttonRolesElement.style.left = `${that.clickedButton.offsetLeft - 172 - payLoad}px`;
      }
    });
  },
  watch: {
    buttons: {
      deep: true,
      handler: function() {
        this.submit();
      }
    }
  },
  computed: {
    buttonRoles() {
      let temp = [
        {
          name: "فتح رابط",
          value: "OPEN_URL"
        },
        {
          name: "إرسال رسالة نصية",
          value: "SEND_TEXT"
        }
      ];
      if (this.$getAtPath(["chatbot", "published"], this.$store.getters["page/services"])) {
        temp.push({ name: "إظهار فئة منتجات معينة", value: "OPEN_CATEGORY" });
      }
      if (this.$getAtPath(["contact", "enabled"], this.$store.getters["page/services"])) {
        temp.push({ name: "عرض معلومات التواصل", value: "OPEN_CONTACTUS" });
      }
      return temp;
    },
    stagedButtonData_computed() {
      return this.$getAtPath(["role", "data"], this.stagedButton);
    }
  }
};
