<template>
  <div class="facebook-template-card button-template">
    <div
      class="image"
      @click="$refs.listCardImage.click()"
      :style="`background-image: url(${img_url})`"
      :class="{ empty: file == null && img_url == null }"
    >
      <input
        id="inputFile"
        style=" opacity: 0; height: 0px; width: 0px;"
        type="file"
        name="file"
        ref="listCardImage"
        accept="image/png, image/jpeg"
        @change="onFileSelected"
      />
      <div v-if="loading" class="m-progress-container">
        <div class="m-progress-bar" :style="`width: ${progress}%;`"></div>
      </div>
    </div>

    <div class="facebook-template-card__controll ltr">
      <div
        class="card-controll__button"
        :class="{ 'error-input': $v.buttons.$each[index].$error }"
        v-for="(button, index) in buttons"
        :key="index"
      >
        <input
          class="button__name"
          @blur="$v.buttons.$each[index].$touch"
          type="text"
          v-model="button.name"
          placeholder="اسم الزر"
          maxlength="20"
        />
        <span class="button__icon">
          <span
            class="icon-container relative"
            @click="onShowRolesClicked(button, index, $event)"
            :class="{
              'z-10': index === selectedButtonIndex,
              'role-entered': button.role != null && !!button.role.action
            }"
          >
            <font-awesome-icon v-if="!button.role" icon="cog" size="sm" />
            <font-awesome-icon
              v-else-if="button.role.action === 'OPEN_URL'"
              icon="link"
              size="sm"
            />
            <font-awesome-icon
              v-else-if="button.role.action === 'OPEN_CATEGORY'"
              icon="tag"
              size="sm"
            />
            <font-awesome-icon
              v-else-if="button.role.action === 'OPEN_CONTACTUS'"
              icon="phone"
              size="sm"
            />
            <font-awesome-icon
              v-else-if="button.role.action === 'SEND_TEXT'"
              icon="align-right"
              size="sm"
            />
          </span>
          <span
            class="icon-container relative text-white bg-mujeeb-red"
            @click="onDeleteButton(index)"
          >
            <font-awesome-icon icon="trash-alt" size="sm" />
          </span>
          <div
            :id="id + '' + index"
            class="button__roles shadow-lg"
            v-if="showButtonRoles && selectedButtonIndex === index"
          >
            <p
              v-for="(role, index_2) in buttonRoles"
              :key="index_2"
              @click="onRoleClicked(button, role.value, index)"
            >
              {{ role.name }}
            </p>
          </div>
        </span>
      </div>
      <button v-if="buttons.length < buttonsMaxNum" @click="onAddNewButton">إضافة زر</button>
    </div>
    <buttonRoleModal
      v-if="showRolesModal"
      :selectedRole="selectedRole"
      :value_prop="stagedButtonData_computed"
      @close="showRolesModal = false"
      @submit="onRoleDataSubmited"
    ></buttonRoleModal>
  </div>
</template>

<script>
import templatesTypes from "./config/templatesTypes";
import buttonRoleModal from "./modal/ButtonRoleModal";
import facebookTemplateButtonMixin from "./../../../../mixins/facebookTemplateButtonMixin";
import imageUploadMixin from "./../../../../mixins/imageUploadMixin";
import { EventBus } from "./../../../../utils/EventBus";
export default {
  name: "imageTemplate",
  mixins: [facebookTemplateButtonMixin, imageUploadMixin],
  components: {
    buttonRoleModal
  },
  props: {
    replyData_prop: {
      type: Object
    },
    buttonsMaxNum: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      ...this.__parseDataProperties("imageTemplate"),
      name: "imageTemplate",
      id: this.$randomId(6)
    };
  },
  // the only function without automatic values
  created() {
    EventBus.$on("vuelidate_touch", this.$v.$touch);
    if (this.replyData_prop) {
      let temp = JSON.parse(JSON.stringify(this.replyData_prop));
      this.img_url = temp.img_url;
      this.buttons = temp.buttons;
      this.title = temp.title;
      this.subtitle = temp.subtitle;
    } else {
      this.onAddNewButton();
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      this.$emit("input", { data: this.templateData_computed, valid: !this.$v.$invalid });
    },
    __parseDataProperties(name) {
      return Object.keys(templatesTypes.inputs[name]).reduce((acc, inputName) => {
        let input = templatesTypes.inputs[name][inputName];
        acc[inputName] = JSON.parse(JSON.stringify(input.default));
        return acc;
      }, {});
    },
    upload() {
      const formData = new FormData();
      formData.append("pageId", this.$store.getters["page/id"]);
      formData.append("userId", this.$store.getters["user/id"]);
      formData.append("image", this.file, this.file.name);
      this.loading = true;
      this.progress = 0 // eslint-disable-line
      const option = {
        baseURL: `https://images.services.mujeeb.ai/image`,
        method: "post",
        responseType: "json",
        data: formData,
        onUploadProgress: progressEvent => {
          this.progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
        }
      };
      return this.$api
        .customRequest(option)
        .then(({ data }) => {
          this.img_url = data.img.CDN_url;
          this.$emit("IMAGE_CHANGED", this.img_url);
          return this.$api.customRequest({
            method: "post",
            url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/upload-to-facebook`,
            data: {
              img_url: this.img_url
            },
            responseType: "json"
          });
        })
        .then(({ data }) => {
          this.attachment_id = data.attachment_id;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    img_url: function(newVal) {
      this.submit();
    },
    attachment_id: function(newVal) {
      this.submit();
    }
  },
  computed: {
    templateData_computed() {
      let temp = Object.keys(templatesTypes.inputs[this.name]).reduce((acc, inputName) => {
        acc[inputName] = this[inputName];
        return acc;
      }, {});
      return JSON.parse(JSON.stringify(temp));
    }
  },
  validations() {
    return templatesTypes.validations[this.name];
  }
};
</script>

<style lang="scss" scoped>
.image {
  width: 100%;
  height: 250px;
  background-position: 50% 50%;
  // background-image: url("https://i.imgur.com/BdYpDAV.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  &:hover {
    border: 2px dashed dodgerblue;
  }
  &.empty {
    border: 2px dashed #ff5678;
  }
  .m-progress-container {
    width: 90%;
    margin: auto;
    .m-progress-bar {
      height: 5px;
      background: dodgerblue;
    }
  }
}
.template-text-area {
  width: 100%;
  padding: 8px;
  text-align: right;
}
.facebook-template-card__controll {
  .controll-title,
  .controll-subtitle {
    height: 35px;
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.253);
    border-radius: 6px;
    padding: 3px 4px;
    direction: rtl;
    text-align: right;
  }
  .controll-subtitle {
    margin: 5px 0px;
  }
  .card-controll__button {
    padding: 0px 5px;
    &.error-input {
      border: 1px solid red;
    }
    .button__name {
      text-align: center;
      width: 70%;
    }
    .button__icon {
      width: 30%;
      position: relative;
      display: flex;
      svg {
        z-index: 10;
        color: white;
      }
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0px;
        right: 110%;
        background: rgba(146, 146, 146, 0.521);
      }
      .button__roles {
        border: 1px solid rgba(128, 128, 128, 0.144);
        // display: none;
        z-index: 9;
        position: absolute;
        top: 110%;
        right: 0px;
        background: white;
        border-radius: 6px;
        padding: 5px;
        color: rgb(16, 139, 255);
        text-align: right;
        &.show {
          display: block;
        }
        p {
          white-space: nowrap;
          margin-bottom: 5px;
          padding: 10px;
          border-radius: 6px;
          &:hover {
            background: rgb(16, 139, 255);
            color: white;
          }
          &:last-child {
            margin-bottom: 0px;
            &::after {
              display: none;
            }
          }
        }
      }
      .icon-container {
        flex: 1 1 auto;
        display: inline-block;
        height: 100%;
        border-radius: 6px;
        background: rgb(16, 139, 255);
        margin-left: 5px;
        &:first-child {
          margin-left: 0px;
        }
      }
    }
  }
}

// @media only screen and (max-width: 500px) {
//   .facebook-template-card {
//     padding: 0px;
//   }
// }
</style>
