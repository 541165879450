export default {
  data() {
    return {
      file: null,
      img_url: this.$store.getters["page/picture"],
      loading: false,
      progress: 0,
      base64Image: "",
      fileTypes: ["image/jpeg", "image/pjpeg", "image/png"]
    };
  },
  methods: {
    validateFileType(file) {
      for (var i = 0; i < this.fileTypes.length; i++) {
        if (file.type === this.fileTypes[i]) {
          return true;
        }
      }
      return false;
    },
    upload() {
      const formData = new FormData();
      formData.append("pageId", this.$store.getters["page/id"]);
      formData.append("userId", this.$store.getters["user/id"]);
      formData.append("image", this.file, this.file.name);
      this.loading = true;
      this.progress = 0// eslint-disable-line
      const option = {
        baseURL: `https://images.services.mujeeb.ai/image`,
        method: "post",
        responseType: "json",
        data: formData,
        onUploadProgress: progressEvent => {
          this.progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
        }
      };
      return this.$api
        .customRequest(option)
        .then(({ data }) => {
          this.img_url = data.img.CDN_url;
          this.$emit("IMAGE_CHANGED", this.img_url);
        })
        .catch(error => {
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء رفع الصورة",
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onFileSelected(e) {
      this.file = null;
      this.base64Image = "";
      this.img_url = "";
      // get file object
      this.file = this.$refs.listCardImage.files[0];
      if (this.file) {
        // check if the file has an accepted extension
        if (!this.validateFileType(this.file)) {
          // TODO alert the user to enter the right type
          this.file = null;
          return;
        }
        // get base64 representation of the file
        const reader = new FileReader();
        reader.onload = e => {
          this.base64Image = e.target.result;
          this.upload();
        };
        reader.readAsDataURL(this.file);
      }
    },
    onFileSelected_2(e) {
      this.file = null;
      this.base64Image = "";
      this.img_url = "";
      // get file object
      this.file = this.$refs.listCardImage.files[0];
      if (this.file) {
        // check if the file has an accepted extension
        if (!this.validateFileType(this.file)) {
          // TODO alert the user to enter the right type
          this.file = null;
          return;
        }
        // get base64 representation of the file
        const reader = new FileReader();
        reader.onload = e => {
          this.base64Image = e.target.result;
          this.upload();
        };
        reader.readAsDataURL(this.file);
      }
    }
  }
};
