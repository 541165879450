<template>
  <div
    class="overflow-y-auto fixed top-0 right-0 bottom-0 left-0 w-full h-full flex justify-center align-center items-center bg-transparant-black z-10"
  >
    <div
      class="reply-input-modal text-right block reply-modal rounded-sm shadow bg-white p-8 small:w-5/6 md:w-11/12 overflow-y-auto m-auto"
    >
      <p class="text-2xl font-bold mb-4 text-center">معلومات الإجابة</p>

      <p class="rtl mb-2">نوع الرسالة:</p>
      <b-select
        expanded
        class="rtl"
        placeholder="اختر نمط الإجابة"
        v-model="selectedReplyTemplate"
        style="text-align: right;"
      >
        <option
          v-for="replyTemplate in repliesTemplates"
          :value="replyTemplate.value"
          :key="replyTemplate.value"
        >
          {{ replyTemplate.name }}
        </option>
      </b-select>
      <div
        class="mt-6 mb-16 rtl flex justify-center"
        :style="selectedReplyTemplate === 'carouselTemplate' ? 'display: block;' : ''"
      >
        <buttonsTemplate
          v-if="selectedReplyTemplate === 'buttonsTemplate'"
          :replyData_prop="replyMessageData"
          @input="onTemplateInput"
        ></buttonsTemplate>
        <textTemplate
          v-if="selectedReplyTemplate === 'textTemplate'"
          :replyData_prop="replyMessageData"
          @input="onTemplateInput"
        ></textTemplate>
        <imageTemplate
          v-if="selectedReplyTemplate === 'imageTemplate'"
          :buttonsMaxNum="1"
          :replyData_prop="replyMessageData"
          @input="onTemplateInput"
        ></imageTemplate>
        <carouselTemplate
          v-if="selectedReplyTemplate === 'carouselTemplate'"
          :replyData_prop="replyMessageData"
          @input="onTemplateInput"
        ></carouselTemplate>
        <mediaTemplate
          v-if="selectedReplyTemplate === 'imageFile'"
          @input="onTemplateInput"
          :replyData_prop="replyMessageData">
        </mediaTemplate>
      </div>

      <div class="action-field rtl" v-for="(input, index) in inputs_computed" :key="index">
        <div class="m-field-label">
          <label class="label">{{ input.inputField_title }}:</label>
          <div class="m-tooltip">
            <img
              class="big-tabs__help-icon"
              src="../../../assets/svg/icon.svg"
              alt="Info icon"
              width="15"
              height="15"
            />
            <span class="tooltip-content">{{ input.tooltip.text }}</span>
          </div>
        </div>
        <div class="m-field-input rtl" :class="input.style">
          <multiselect
            v-if="input.type === 'inputTag-autocomplete'"
            style="direction: rtl;"
            :loading="loading"
            placeholder="اختر قوالب التعليقات الملائمة"
            v-model="input.value"
            :multiple="true"
            :close-on-select="false"
            track-by="id"
            label="name"
            selectLabel=""
            :taggable="true"
            selectedLabel="تم اختيارها"
            deselectLabel="اضغط لاستبعاد هذه الفئة"
            noOptions="لم يتم إدخال فئات بعد"
            :options="options"
          >
            <span slot="noOptions">لم يتم إدخال فئات حتى الآن</span>
          </multiselect>
          <b-input v-if="input.type === 'textarea'" type="textarea" v-model="input.value"></b-input>
          <b-taginput
            :confirm-key-codes="[13, 9]"
            v-if="input.type === 'inputTag'"
            v-model="input.value"
            ellipsis
            placeholder="الكلمات المفتاحية"
            :expanded="true"
          >
          </b-taginput>
          <b-switch class="ltr self-end" v-if="input.type === 'checkbox'" v-model="input.value">
            {{ input.value ? "نعم" : "لا" }}
          </b-switch>
        </div>
      </div>

      <div class="text-center">
        <button class="mujeeb-button big" :class="{ green: canSubmit }" @click="submit">
          {{ reply_prop ? "تعديل" : "إضافة" }}
        </button>
        <button class="mujeeb-button big" @click="$emit('close')">إلغاء</button>
      </div>
    </div>
  </div>
</template>

<script>
import buttonsTemplate from "./facebookTemplates/buttonsTemplate";
import textTemplate from "./facebookTemplates/textTemplate";
import imageTemplate from "./facebookTemplates/imageTemplate";
import carouselTemplate from "./facebookTemplates/carouselTempale";
import templatesTypes from "./facebookTemplates/config/templatesTypes.js";
import mediaTemplate from "./facebookTemplates/mediaTemplate"
import { EventBus } from "./../../../utils/EventBus";
import Multiselect from "vue-multiselect";
export default {
  components: {
    buttonsTemplate,
    textTemplate,
    imageTemplate,
    carouselTemplate,
    Multiselect,
    mediaTemplate
  },
  props: {
    reply_type_prop: {
      type: String
    },
    reply_prop: {
      type: Object
    }
  },
  data() {
    return {
      repliesTemplates: templatesTypes.types,
      selectedReplyTemplate: "textTemplate",
      localReplyMessageData: null,
      replyMessageData: null,
      valid: false,
      loading: false,
      options: [
        {
          name: "التعليقات الافتراضية (من مجيب)",
          id: "DEFAULT_TEMPLATE"
        }
      ],
      customReplyInputs: [
        {
          name: "replyTo",
          inputField_title: "الكلمات المفتاحية",
          tooltip: {
            text: "سيتم الإجابة على التعليقات التي تحوي هذه الكلمات. بعد كتابة كل كلمة اضغط enter"
          },
          value: [],
          type: "inputTag",
          style: "ltr"
        },
        {
          name: "welcom",
          inputField_title: "الرد بجملة ترحيبية كتعليق",
          tooltip: {
            text: "الرد بتعليق ترحيبي على التعليقات "
          },
          value: false,
          type: "checkbox",
          style: {
            flex: true,
            "justify-center": true
          }
        },
        {
          name: "tags",
          inputField_title: "الإجابة على mention",
          tooltip: {
            text: "الرد بتعليق ترحيبي على المنشن"
          },
          value: false,
          type: "checkbox",
          style: {
            flex: true,
            "justify-center": true
          }
        },
        {
          name: "comment_templates",
          inputField_title: "قوالب التعليقات",
          tooltip: {
            text: "سيتم الإجابة على التعليقات التي تحوي هذه الكلمات. بعد كتابة كل كلمة اضغط enter"
          },
          value: [
            {
              name: "التعليقات الافتراضية (من مجيب)",
              id: "DEFAULT_TEMPLATE"
            }
          ],
          type: "inputTag-autocomplete",
          style: "ltr"
        }
      ],
      defaultReplyInputs: [
        {
          name: "welcom",
          inputField_title: "الرد بجملة ترحيبية كتعليق",
          tooltip: {
            text: "الرد بتعليق ترحيبي على التعليقات "
          },
          value: false,
          type: "checkbox"
        },
        {
          name: "tags",
          inputField_title: "الإجابة على mention",
          tooltip: {
            text: "وضع بتعليق ترحيبي على المنشن"
          },
          value: false,
          type: "checkbox"
        },
        {
          name: "comment_templates",
          inputField_title: "قوالب التعليقات",
          tooltip: {
            text: "سيتم الإجابة على التعليقات التي تحوي هذه الكلمات. بعد كتابة كل كلمة اضغط enter"
          },
          value: [
            {
              name: "التعليقات الافتراضية (من مجيب)",
              id: "DEFAULT_TEMPLATE"
            }
          ],
          type: "inputTag-autocomplete",
          style: "ltr"
        }
      ]
    };
  },
  created() {
    if (this.reply_prop) {
      this.localReplyMessageData = this.deepCopy(this.reply_prop);
      this.replyMessageData = this.localReplyMessageData.message.data;
      this.selectedReplyTemplate = this.localReplyMessageData.message.type;

      const replyType =
        this.reply_type_prop === "custom" ? "customReplyInputs" : "defaultReplyInputs";
      for (let index = 0; index < this[replyType].length; index++) {
        let input = this[replyType][index];
        if (input.name === "comment_templates") {
          if (this.localReplyMessageData[input.name] != null) {
            input.value = this.localReplyMessageData[input.name];
          }
        } else {
          input.value = this.localReplyMessageData[input.name];
        }
      }
      this.valid = true;
    }
    this.loading = true;
    this.$api
      .customRequest({
        method: "get",
        url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/comment-template/all`
      })
      .then(({ data }) => {
        this.options = [...this.options, ...data.templates];
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    deepCopy(reply) {
      // TODO make a deep copy
      return JSON.parse(JSON.stringify(reply));
    },
    encode(reply) {
      return JSON.parse(JSON.stringify(reply));
    },
    OnTypeChanged(value) {
      if (this.reply_prop) {
        if (value !== this.reply_prop.type) {
          this.replyMessageData = null;
          this.valid = false;
        } else {
          this.replyMessageData = this.localReplyMessageData;
        }
      } else {
        this.valid = false;
        this.replyMessageData = null;
        this.selectedReplyTemplate = value;
      }
    },
    submit() {
      if (this.valid && this.keywords_and_switch_validation()) {
        let payLoad = { data: this.finalReplyData_computed };
        if (this.reply_prop) {
          payLoad.action = "edit";
          if (this.reply_type_prop === "custom") {
            payLoad.id = this.reply_prop.id;
          }
        } else {
          payLoad.action = "new";
        }
        payLoad.type = this.reply_type_prop;
        // console.log(payLoad);
        this.$emit("SUBMIT_REPLY", payLoad);
      } else {
        EventBus.$emit("vuelidate_touch");
        this.$buefy.toast.open({
          duration: 5000,
          queue: false,
          message: "يجب تعبئة الحقول الفارغة",
          position: "is-top",
          type: "is-danger"
        });
      }
    },
    onTemplateInput(templateData) {
      this.valid = templateData.valid;
      if (this.valid) {
        this.replyMessageData = templateData.data;
      }
    },
    keywords_and_switch_validation() {
      let temp_validation = true;
      for (let input of this.inputs_computed) {
        if (input.name === "replyTo" || input.name === "comment_templates") {
          // if (this.reply_type_prop === "custom") {
          temp_validation = temp_validation && input.value != null && input.value.length > 0;
          // }
        } else {
          temp_validation = temp_validation && input.value != null;
        }
      }
      return temp_validation;
    }
  },
  computed: {
    canSubmit() {
      return this.valid && this.keywords_and_switch_validation();
    },
    finalReplyData_computed() {
      if (this.reply_type_prop === "custom") {
        return {
          message: { data: this.replyMessageData, type: this.selectedReplyTemplate },
          replyTo: this.inputs_computed[0].value,
          welcom: this.inputs_computed[1].value,
          tags: this.inputs_computed[2].value,
          comment_templates: this.inputs_computed[3].value
        };
      } else {
        return {
          message: { data: this.replyMessageData, type: this.selectedReplyTemplate },
          welcom: this.inputs_computed[0].value,
          tags: this.inputs_computed[1].value,
          comment_templates: this.inputs_computed[2].value
        };
      }
    },
    inputs_computed() {
      if (this.reply_type_prop === "custom") {
        return this.customReplyInputs;
      } else {
        return this.defaultReplyInputs;
      }
    }
  },
  watch: {
    selectedReplyTemplate: function(value) {
      if (this.reply_prop) {
        if (value !== this.reply_prop.type) {
          this.replyMessageData = null;
          this.valid = false;
        } else {
          this.replyMessageData = this.localReplyMessageData;
        }
      } else {
        this.valid = false;
        this.replyMessageData = null;
        this.selectedReplyTemplate = value;
      }
    }
  }
};
</script>

<style lang="scss">
.reply-input-modal {
  width: 100% !important;
}

.action-field {
  .m-field-input {
    .multiselect {
      .multiselect__tag {
        color: #4a4a4a;
        background: #f5f5f5;
        padding: 7px 26px 7px 10px;
        .multiselect__tag-icon {
          border-radius: 0px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          &::after {
            color: #4a4a4a;
          }
          &:hover {
            background: #ff6a6a;
            color: white !important;
          }
        }
      }
      .multiselect__select {
        &::before {
          top: 86%;
        }
      }
      .multiselect__content-wrapper {
        .multiselect__content {
          .multiselect__element {
            .multiselect__option--highlight {
              background: dodgerblue;
            }
            .multiselect__option--selected {
              background: #ff6a6a;
              color: white;
              &::after {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .reply-input-modal {
    padding: 5px !important;
  }
}
</style>
