export default {
  data() {
    return {
      file_size: null,
      file_obj: null,
      loading: false,
      image_blob: null,
      uploaded_image_url: null,
      progress: 0
      // fileTypes: ["image/jpeg", "image/pjpeg", "image/png", "audio/aac", "audio/mpeg", "video/mpeg", "application/pdf", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.rar", "audio/wav", "application/vnd.ms-excel", "video/3gpp", "audio/3gpp", "application/x-7z-compressed"]
    };
  },
  methods: {
    upload() {
      const formData = new FormData();
      formData.append("pageId", this.$store.getters["page/id"]);
      formData.append("userId", this.$store.getters["user/id"]);
      formData.append("image", this.file, this.file.name);
      this.loading = true;
        this.progress = 0// eslint-disable-line
      const option = {
        baseURL: `https://images.services.mujeeb.ai/image`,
        method: "post",
        responseType: "json",
        data: formData,
        onUploadProgress: progressEvent => {
          this.progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
        }
      };
      return this.$api
        .customRequest(option)
        .then(({ data }) => {
          this.img_url = data.img.CDN_url;
          this.$emit("IMAGE_CHANGED", this.img_url);
        })
        .catch(error => {
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء رفع الصورة",
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onFileSelected(e) {
      this.resetFileObj();
      if (!e.target.files[0]) {
        this.resetFileObj();
        return;
      }

      if (e.target.files[0].size > 10000000) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "حجم الملف المدخل أكبر من 10 ميفا بايت",
          position: "is-top",
          type: "is-danger"
        });
        this.resetFileObj(true);
        return;
      }

      this.file_obj = e.target.files[0];
      this.file_size = e.target.files[0].size;
      this.file_type = e.target.files[0].type.startsWith("image/") ? "image" : "file";
      this.file_name = e.target.files[0].name;

      if (this.file_type === "image") {
        const reader = new FileReader();
        const context = this;
        reader.onload = function(e) {
          context.image_blob = e.target.result;
        };
        reader.readAsDataURL(this.file_obj);
      }

      const formData = new FormData();
      formData.append("pageId", this.$store.getters["page/id"]);
      formData.append("userId", this.$store.getters["user/id"]);
      formData.append("file", this.file_obj, this.file_name);
      formData.append("file_name", this.file_name);
      const option = {
        //  image-uploader.eu-central-1.elasticbeanstalk.com
        // https://mujeeb-image-uploader.herokuapp.com/file
        // `http://localhost:3000/file`
        baseURL: `https://images.services.mujeeb.ai/file`,
        method: "post",
        responseType: "json",
        data: formData,
        // headers: {
        //   "Content-Type": "multipart/form-data"
        // }
        onUploadProgress: progressEvent => {
          this.progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
        }
      };
      console.log("done fetching file");
      this.loading = true
      return this.$api
        .customRequest(option)
        .then(({ data }) => {
          console.log(data);
          if (this.file_type === "image") {
            this.uploaded_image_url = data.img.CDN_url
          }
          this.file_URL = data.img.CDN_url
        })
        .catch(err => {
          console.log(err);
          this.$buefy.toast.open({
            duration: 3000,
            message: err.response.data,
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetFileObj(showFileSelectView = false) {
      this.file_URL = null;
      this.file_name = null;
      this.file_type = null;
      this.file_size = null;
      this.file_obj = null;
      this.image_blob = null;
      this.progress = 0;
      this.uploaded_image_url = null;
      if (showFileSelectView) {
        this.$refs.fileInput.click();
      }
    }
  }
};
