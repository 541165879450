<template>
  <div class="facebook-template-card button-template">
    <textarea-autosize
      placeholder="أدخل النص الذي تريد إرساله في البطاقة"
      v-model="$v.message.$model"
      :min-height="30"
      maxLength="600"
      class="template-text-area"
      :class="{ 'error-input': $v.message.$error }"
    />
    <p class="text-sm ltr pr-2">600 / {{ $v.message.$model ? $v.message.$model.length : 0 }}</p>
    <div class="facebook-template-card__controll ltr">
      <div
        class="card-controll__button"
        :class="{ 'error-input': $v.buttons.$each[index].$error }"
        v-for="(button, index) in buttons"
        :key="index"
      >
        <input
          class="button__name"
          type="text"
          @blur="$v.buttons.$each[index].$touch"
          v-model="button.name"
          placeholder="اسم الزر"
          maxlength="20"
        />
        <span class="button__icon">
          <span
            class="icon-container relative"
            @click="onShowRolesClicked(button, index, $event)"
            :class="{
              'z-10': index === selectedButtonIndex,
              'role-entered': button.role != null && !!button.role.action
            }"
          >
            <font-awesome-icon v-if="!button.role" icon="cog" size="sm" />
            <font-awesome-icon
              v-else-if="button.role.action === 'OPEN_URL'"
              icon="link"
              size="sm"
            />
            <font-awesome-icon
              v-else-if="button.role.action === 'OPEN_CATEGORY'"
              icon="tag"
              size="sm"
            />
            <font-awesome-icon
              v-else-if="button.role.action === 'OPEN_CONTACTUS'"
              icon="phone"
              size="sm"
            />
            <font-awesome-icon
              v-else-if="button.role.action === 'SEND_TEXT'"
              icon="align-right"
              size="sm"
            />
          </span>
          <span
            class="icon-container relative text-white bg-mujeeb-red"
            @click="onDeleteButton(index)"
          >
            <font-awesome-icon icon="trash-alt" size="sm" />
          </span>
          <div
            :id="id + '' + index"
            class="button__roles shadow-lg"
            v-if="showButtonRoles && selectedButtonIndex === index"
          >
            <p
              v-for="(role, index_2) in buttonRoles"
              :class="{ 'selected-role': button.role != null && role.value === button.role.action }"
              :key="index_2"
              @click="onRoleClicked(button, role.value, index)"
            >
              {{ role.name }}
            </p>
          </div>
        </span>
      </div>
      <button
        v-if="buttons.length < 3"
        @click="onAddNewButton"
        :class="{ 'error-input': buttons.length === 0 }"
      >
        <font-awesome-icon icon="plus" size="sm" /> إضافة زر
      </button>
    </div>
    <buttonRoleModal
      v-if="showRolesModal"
      :selectedRole="selectedRole"
      :value_prop="stagedButtonData_computed"
      @close="showRolesModal = false"
      @submit="onRoleDataSubmited"
    ></buttonRoleModal>
  </div>
</template>

<script>
import templatesTypes from "./config/templatesTypes";
import buttonRoleModal from "./modal/ButtonRoleModal";
import facebookTemplateButtonMixin from "./../../../../mixins/facebookTemplateButtonMixin";
import { EventBus } from "./../../../../utils/EventBus";
export default {
  name: "buttonsTemplate",
  mixins: [facebookTemplateButtonMixin],
  components: {
    buttonRoleModal
  },
  props: {
    replyData_prop: {
      type: Object
    }
  },
  data() {
    return {
      ...this.__parseDataProperties("buttonsTemplate"),
      name: "buttonsTemplate",
      id: this.$randomId(6)
    };
  },
  // the only function without automatic values
  created() {
    EventBus.$on("vuelidate_touch", this.$v.$touch);
    if (this.replyData_prop) {
      let temp = JSON.parse(JSON.stringify(this.replyData_prop));
      this.message = temp.message;
      this.buttons = temp.buttons;
    } else {
      this.onAddNewButton();
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      this.$emit("input", { data: this.templateData_computed, valid: this.canSubmit_computed });
    },
    __parseDataProperties(name) {
      return Object.keys(templatesTypes.inputs[name]).reduce((acc, inputName) => {
        let input = templatesTypes.inputs[name][inputName];
        acc[inputName] = JSON.parse(JSON.stringify(input.default));
        return acc;
      }, {});
    }
  },
  watch: {
    message: function(newVal) {
      this.submit();
    }
  },
  computed: {
    canSubmit_computed() {
      return !this.$v.$invalid;
    },
    templateData_computed() {
      let temp = Object.keys(templatesTypes.inputs[this.name]).reduce((acc, inputName) => {
        acc[inputName] = this[inputName];
        return acc;
      }, {});
      return JSON.parse(JSON.stringify(temp));
    }
  },
  validations() {
    return templatesTypes.validations.buttonsTemplate;
  }
};
</script>

<style lang="scss" scoped>
.template-text-area {
  width: 100%;
  padding: 8px;
  text-align: right;
  background: rgb(218, 218, 218);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  &.error-input {
    border: 1px solid red;
  }
}
.card-controll__button {
  padding: 0px 5px;
  &.error-input {
    border: 1px solid red;
  }
  .button__name {
    text-align: center;
    width: 70%;
  }
  .button__icon {
    width: 30%;
    position: relative;
    display: flex;
    svg {
      z-index: 10;
      color: white;
    }
    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0px;
      right: 110%;
      background: rgba(146, 146, 146, 0.521);
    }
    .button__roles {
      border: 1px solid rgba(128, 128, 128, 0.144);
      // display: none;
      z-index: 9;
      position: absolute;
      top: 110%;
      right: 0px;
      background: white;
      border-radius: 6px;
      padding: 5px;
      color: rgb(16, 139, 255);
      text-align: right;
      &.show {
        display: block;
      }
      p {
        white-space: nowrap;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 6px;
        &:hover {
          background: rgb(16, 139, 255);
          color: white;
        }
        &:last-child {
          margin-bottom: 0px;
          &::after {
            display: none;
          }
        }
        &.selected-role {
          background: rgb(16, 139, 255);
          color: white;
        }
      }
    }
    .icon-container {
      flex: 1 1 auto;
      display: inline-block;
      height: 100%;
      border-radius: 6px;
      background: rgb(16, 139, 255);
      margin-left: 5px;
      &:first-child {
        margin-left: 0px;
      }
      &.role-entered {
        background: $success;
      }
      &.bg-mujeeb-red {
        background: $danger;
      }
    }
  }
}

.facebook-template-card__controll {
  &.error-input {
    border: 1px solid red;
  }
  button {
    &.error-input {
      border: 1px solid red;
    }
    svg {
      margin: 10px;
    }
  }
}
</style>
