import { render, staticRenderFns } from "./cardTemplate.vue?vue&type=template&id=53b8e71c&scoped=true&"
import script from "./cardTemplate.vue?vue&type=script&lang=js&"
export * from "./cardTemplate.vue?vue&type=script&lang=js&"
import style0 from "./cardTemplate.vue?vue&type=style&index=0&id=53b8e71c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b8e71c",
  null
  
)

export default component.exports