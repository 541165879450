<template>
  <div>
    <div class="flex justify-center align-center" :class="{ 'mb-8': cards && cards.legnth > 0 }">
      <button class="mujeeb-button green big" @click="addNewCard" v-if="cards.length < 10">
        إضافة بطاقة<font-awesome-icon icon="plus" />
      </button>
    </div>
    <div
      class="image-template-carousel ltr overflow-x-auto overflow-y-hidden z-10 flex content-center items-center"
      :class="{ 'empty-list': cards.length === 0 }"
    >
      <div class="image-template" v-for="(card, index) in cards" :key="card.id">
        <p class="close-button" @click="removeCard(index)">
          <font-awesome-icon icon="trash-alt" /> حذف
        </p>
        <cardTemplate :replyData_prop="card" @input="onTemplateInput($event, index)"></cardTemplate>
      </div>
    </div>
  </div>
</template>

<script>
import templatesTypes from "./config/templatesTypes";
import cardTemplate from "./cardTemplate";
import { EventBus } from "./../../../../utils/EventBus";
import debounce from "debounce";
export default {
  name: "carouselTemplate",
  components: {
    cardTemplate
  },
  props: {
    replyData_prop: {
      type: Array
    }
  },
  data() {
    return {
      ...this.__parseDataProperties("carouselTemplate"),
      name: "carouselTemplate",
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 3,
          nav: false
        },
        1000: {
          items: 5,
          nav: true,
          loop: false
        }
      }
    };
  },
  created() {
    if (this.replyData_prop) {
      let temp = JSON.parse(JSON.stringify(this.replyData_prop));
      for (let index = 0; index < temp.length; index++) {
        let card = temp[index];
        card.id = this.$randomId(6);
      }
      this.cards = temp;
    }
  },
  mounted() {
    document
      .getElementsByClassName("image-template-carousel")[0]
      .addEventListener("scroll", function(e) {
        EventBus.$emit("carousel_scroll", e.target.scrollLeft);
      });
  },
  methods: {
    removeCard(index) {
      this.$delete(this.cards, index);
    },
    addNewCard() {
      if (this.cards.length < 10) {
        this.$set(this.cards, this.cards.length, {
          ...this.__parseDataProperties("cardTemplate"),
          id: this.$randomId(6)
        });
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          queue: false,
          message: `لا يمكن إضافة أكثر من 10 بطاقات`,
          position: "is-top",
          type: "is-warn"
        });
      }
    },
    submit() {
      this.$v.$touch();
      this.$emit("input", { data: this.cards, valid: !this.$v.$invalid });
    },
    __parseDataProperties(name) {
      return Object.keys(templatesTypes.inputs[name]).reduce((acc, inputName) => {
        let input = templatesTypes.inputs[name][inputName];
        acc[inputName] = JSON.parse(JSON.stringify(input.default));
        return acc;
      }, {});
    },
    onTemplateInput(templateData, index) {
      if (templateData.valid) {
        this.$set(this.$v.cards.$model, index, { ...templateData.data, id: this.cards[index].id });
      }
    }
  },
  watch: {
    cards: {
      handler: function() {
        this.submit();
      },
      deep: true
    }
  },
  validations() {
    return templatesTypes.validations[this.name];
  }
};
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
}
.add-new-card {
  cursor: pointer;
  margin-bottom: 39px;
  background: $success;
  color: white;
  border-radius: 6px;
  padding: 10px;
  display: inline-block;
  white-space: nowrap;
  p {
    font-weight: bold;
    svg {
      margin-right: 10px;
    }
  }
}
* {
  p {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
.image-template-carousel {
  &.empty-list {
    display: block;
    text-align: center;
  }
  .image-template {
    padding: 15px;
    .close-button {
      padding: 5px 15px;
      border-radius: 6px;
      background: $danger;
      color: white;
      display: inline-block;
      margin-bottom: 13px;
      cursor: pointer;
    }
    span {
      display: none;
    }
  }
}
@media only screen and (max-width: 500px) {
  .image-template-carousel {
    .image-template {
      &:first-child {
        padding-left: 0px;
      }
    }
  }
}
</style>
