<template>
  <div class="w-full">
    <b-field
      :type="$v.message.$error ? 'is-danger' : ''"
      :message="$v.message.$error ? 'يجب إدخال نص الرسالة' : ''"
    >
      <b-input
        maxlength="2000"
        @blur="$v.message.$touch"
        v-model="$v.message.$model"
        type="textarea"
        placeholder="أدخل محتوى الرسالة الخاصة التي سيتم إرسالها للمستخدم"
      ></b-input>
    </b-field>
  </div>
</template>

<script>
import templatesTypes from "./config/templatesTypes";
import { EventBus } from "./../../../../utils/EventBus";
export default {
  name: "textTemplate",
  props: {
    replyData_prop: {
      type: Object
    }
  },
  data() {
    return {
      ...this.__parseDataProperties("textTemplate"),
      name: "textTemplate"
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      this.$emit("input", { data: { message: this.message }, valid: !this.$v.$invalid });
    },
    __parseDataProperties(name) {
      return Object.keys(templatesTypes.inputs[name]).reduce((acc, inputName) => {
        let input = templatesTypes.inputs[name][inputName];
        acc[inputName] = JSON.parse(JSON.stringify(input.default));
        return acc;
      }, {});
    }
  },
  watch: {
    message: function(newVal) {
      this.submit();
    }
  },
  created() {
    EventBus.$on("vuelidate_touch", this.$v.$touch);
    if (this.replyData_prop) {
      this.message = this.replyData_prop.message;
    }
  },
  validations() {
    return templatesTypes.validations.textTemplate;
  }
};
</script>

<style lang="scss" scoped>
.textarea {
  width: 100%;
}
.template-text-area {
  border-radius: 6px;
  background-color: white;
  border-color: #dbdbdb;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
  border: 1px solid #8787878a;
  direction: rtl;
  text-align: right;
  padding: 5px;
}
</style>
