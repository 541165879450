<template>
    <div class="overflow-y-auto fixed top-0 right-0 bottom-0 left-0 w-full h-full flex justify-center align-center items-center bg-transparant-black  z-10">
      <div class="rtl reply-input-modal block reply-modal rounded-sm shadow bg-white p-8 small:w-5/6 md:w-11/12 overflow-y-auto m-auto">
        <p class="text-xl font-bold text-center mb-6">تحديد وظيفة الزر</p>
        
        <div class="mt-12 mb-4">
          <div v-if="selectedRole === 'SEND_TEXT'">
            <p class="mb-2 text-lg">ادخل الرسالة النصية التي تود إرسالها:</p>
            <b-field :type=" $v.value.$error ? 'is-danger' : null ">
              <b-input maxlength="2000" type="textarea" v-model.trim="$v.value.$model"></b-input>
            </b-field>
            <p class="text-mujeeb-red" v-if="$v.$error">الرجاء إدخال محتوى الرسالة</p>
          </div>

          <div v-if="selectedRole === 'OPEN_URL'">
            <p class="mb-2 text-lg">أدخل الرابط المراد فتحه عند الضغط على الزر:</p>
            <input type="text" class="mujeeb-input" :class="{'error': $v.$error }" v-model.trim="$v.value.$model">
            <p class="text-mujeeb-red" v-if="$v.$error">يجب إدخال رابط صحيح يبدأ بـ http://&#8206 أو https://&#8206</p>
          </div>
          
          <div class="relative h-40">
            <div v-if="selectedRole === 'OPEN_CATEGORY' && !fetchingCategories">
              <p class="mb-2 text-lg">اختر الفئة التي تريد إرسال عناصرها إلى المستحدم:</p>
              <b-select placeholder="اختر فئة من الفئات" expanded :loading="fetchingCategories" v-model="$v.value.$model">
                <option
                    v-for="category in categories"
                    :value="category.key"
                    :key="category.key">
                    {{ category.name }}
                </option>
              </b-select>
            </div>
            <loading-spinner v-if="fetchingCategories" position="absolute"></loading-spinner>
          </div>

        </div>

        <div class="modal-controll">
            <button class="mujeeb-button big" @click="$emit('close')">إغلاق</button>
            <button class="mujeeb-button big" :class="{'green': !$v.$invalid}" :disabled="$v.$invalid" @click="$emit('submit', value)">إدخال</button>
        </div>
      </div>
    </div>
</template>

<script>
import templatesTypes from "../config/templatesTypes"
export default {
  props: {
    selectedRole: {
      type: String
    },
    value_prop: String
  },
  data() {
    return {
      fetchingCategories: false,
      value: null,
      categories: null
    }
  },
  created () {
    this.value = this.value_prop
    if (this.selectedRole === 'OPEN_CATEGORY') {
      this.fetchCategory()
    }
  },
  methods: {
    flatCategories(categories, flat = []) {
      for (let index = 0; index < categories.length; index++) {
        const category = categories[index];
        flat.push({ name: category.name, key: category.id })
        if (category.children_nodes) {
          return this.flatCategories(category.children_nodes, flat)
        }
      }
      return flat
    },
    fetchCategory() {
      this.fetchingCategories = true
      this.$api.customRequest({
        url: `/services/chatbot/server/pages/${this.$store.getters['page/id']}/categories`,
        method: 'get',
        responsType: 'json'
      })
      .then(({ data }) => {
        this.categories = this.flatCategories(data.categories)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.fetchingCategories = false
      })
    }
  },
  validations() {
    return templatesTypes.rolesValidations[this.selectedRole]
  }
}
</script>

<style lang="scss" scoped>
.mujeeb-input {
  border-radius: 6px;
  background-color:white;
  border-color:#dbdbdb;
  color:#363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
  border: 1px solid #8787878a;
  direction: rtl;
  text-align: right;
  padding-right: 7px;
  height: 2.25em;
  &:active, &:focus {
    border-color:dodgerblue;
    box-shadow: 0 0 0 0.125em rgba(30, 144, 255, 0.25);
  }
  &.error {
    border-color: $danger;
    box-shadow: 0 0 0 0.125em #ff38604d;
  }
  &.textarea {
    resize: vertical;
  }
}
.modal-controll {
  text-align: center;
  margin-top: 30px;
}
</style>