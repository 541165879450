const { required, url, maxLength, helpers, requiredIf } = require("vuelidate/lib/validators");
const minL = length => value => {
  if (value) {
    if (value.length != null) {
      return value.length >= length;
    }
    return false;
  }
  return false;
};

const optional = value => !helpers.req(value) || helpers.req(value);

const maxL = length => value => {
  if (value) {
    if (value.length != null) {
      return value.length <= length;
    }
    return false;
  }
  return false;
};

const teplateTypesData = {
  types: [
    {
      value: "carouselTemplate",
      name: "مجموعة بطاقات"
    },
    {
      value: "imageTemplate",
      name: "صورة مع أزرار"
    },
    {
      value: "buttonsTemplate",
      name: "نص مع أزرار"
    },
    {
      value: "textTemplate",
      name: "رسالة نصيّة"
    },
    {
      value: "imageFile",
      name: "ملف أو صورة"
    }
  ],
  inputs: {
    buttonsTemplate: {
      message: {
        type: "String",
        default: null
      },
      buttons: {
        type: "Array",
        // default: [{ name: null, role: null, id: null }],
        default: []
      }
    },
    imageTemplate: {
      img_url: {
        type: "String",
        default: null
      },
      buttons: {
        type: "Array",
        // default: [{ name: null, role: null, id: null }]
        default: []
      },
      attachment_id: {
        type: "String",
        default: null
      }
    },
    cardTemplate: {
      img_url: {
        type: "String",
        default: null
      },
      title: {
        type: "String",
        default: null
      },
      subtitle: {
        type: "String",
        default: null
      },
      buttons: {
        type: "Array",
        // default: [{ name: null, role: null, id: null }]
        default: []
      }
    },
    textTemplate: {
      message: {
        type: "string",
        default: null
      }
    },
    imageFile: {
      file_URL: {
        type: "string",
        default: null
      },
      file_name: {
        type: "string",
        default: null
      },
      file_type: {
        type: "string",
        default: null
      }
    },
    carouselTemplate: {
      cards: {
        type: "Array",
        default: []
      }
    }
  },
  validations: {
    carouselTemplate: {
      cards: {
        minLength: minL(1),
        maxLength: maxL(10),
        $each: {
          reqired: required,
          title: {
            required
          },
          subtitle: {
            optional
          },
          img_url: {
            required
          },
          buttons: {
            minLength: minL(1),
            $each: {
              name: {
                required
              },
              role: {
                action: {
                  required
                },
                data: {
                  required: requiredIf(function(button) {
                    if (button) {
                      if (button.action === "OPEN_CONTACTUS") {
                        return false;
                      } else {
                        return !button.data;
                      }
                    } else {
                      return true;
                    }
                  })
                }
              },
              id: {
                required: requiredIf(function(button) {
                  if (button) {
                    if (button.action === "SEND_TEXT") {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                })
              }
            }
          }
        }
      }
    },
    cardTemplate: {
      img_url: {
        required
      },
      title: {
        required
      },
      subtitle: {
        optional
      },
      buttons: {
        minLength: minL(1),
        $each: {
          name: {
            required
          },
          role: {
            action: {
              required
            },
            data: {
              required: requiredIf(function(button) {
                if (button) {
                  if (button.action === "OPEN_CONTACTUS") {
                    return false;
                  } else {
                    return !button.data;
                  }
                } else {
                  return true;
                }
              })
            }
          },
          id: {
            required: requiredIf(function(button) {
              if (button) {
                if (button.action === "SEND_TEXT") {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            })
          }
        }
      }
    },
    imageTemplate: {
      img_url: {
        required
      },
      attachment_id: {
        required
      },
      buttons: {
        minLength: minL(1),
        $each: {
          name: {
            required
          },
          role: {
            action: {
              required
            },
            data: {
              required: requiredIf(function(button) {
                if (button) {
                  if (button.action === "OPEN_CONTACTUS") {
                    return false;
                  } else {
                    return !button.data;
                  }
                } else {
                  return true;
                }
              })
            }
          },
          id: {
            required: requiredIf(function(button) {
              if (button) {
                if (button.action === "SEND_TEXT") {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            })
          }
        }
      }
    },
    buttonsTemplate: {
      message: { required },
      buttons: {
        minLength: minL(1),
        $each: {
          name: {
            required
          },
          role: {
            action: {
              required
            },
            data: {
              required: requiredIf(function(button) {
                if (button) {
                  if (button.action === "OPEN_CONTACTUS") {
                    return false;
                  } else {
                    return !button.data;
                  }
                } else {
                  return true;
                }
              })
            }
          },
          id: {
            required: requiredIf(function(button) {
              if (button) {
                if (button.action === "SEND_TEXT") {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            })
          }
        }
      }
    },
    textTemplate: {
      message: {
        required
      }
    },
    imageFile: {
      file_URL: {
        required
      },
      file_name: {
        required
      },
      file_type: {
        required
      }
    }
  },
  rolesValidations: {
    OPEN_URL: {
      value: {
        url,
        required
      }
    },
    SEND_TEXT: {
      value: {
        required,
        maxLength: maxL(2000)
      }
    },
    OPEN_CATEGORY: {
      value: {
        required
      }
    }
  }
};

module.exports = teplateTypesData;
